import request from './request';

export default {
  sendSubscriptionEmail: (holderId) => request.easytip({
    method: 'post',
    url: '/v4/subscriptions/emails',
    data: {
      holderId,
    },
  }),
  getSubscriptionPlans: (workplaceId) => request.easytip({
    method: 'get',
    url: '/v4/subscriptions/plans',
    params: {
      workplaceId,
    },
  }),
  getCurrentSubscription: (workplaceId) => request.easytip({
    method: 'get',
    url: '/v4/subscriptions',
    params: {
      workplaceId,
    },
  }),
  createSubscriptionDraft: (data) => request.easytip({
    method: 'post',
    url: '/v4/subscriptions/drafts',
    data,
  }),
  createSubscription: (data) => request.easytip({
    method: 'post',
    url: '/v4/subscriptions',
    data,
  }),
  setSubscriptionInfo: (subscriptionId, data) => request.easytip({
    method: 'put',
    url: `/v4/subscriptions/${subscriptionId}`,
    data,
  }),
};
